// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '../api'

// ** Third Party Components
// **
export const getAppSetting = createAsyncThunk(
  'article/getAppSetting',
  async (id) => {
    return await axios
      .get(`/api/app-settings/privacy-policy/check`)
      .then((res) => res?.data)
      .catch((err) => err?.data)
  }
)

export const articleAll = createAsyncThunk(
  'article/getData',
  async (params) => {
    const response = await axios.get('/api/article', { params })
    return {
      params,
      data: response.data.data,
      totalPages: response.data.paging.totalPages,
      totalItems: response.data.paging.totalItems
    }
  }
)

export const articleDetail = createAsyncThunk(
  'article/articleDetail',
  async (id) => {
    const response = await axios.get(`/api/article/${id}`)
    return response.data
  }
)
export const _ = createSlice({
  name: 'article',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selected: {},
    detail: {},
    privacy: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(articleAll.fulfilled, (state, action) => {
        state.data = action.payload.data || []
        state.params = action.payload.params
        state.total = action.payload.totalItems
        state.selected = {}
      })
      .addCase(articleDetail.fulfilled, (state, action) => {
        state.selected = action.payload?.data
      })
      .addCase(getAppSetting.fulfilled, (state, action) => {
        state.privacy = action.payload?.data
      })
  }
})

export default _.reducer
