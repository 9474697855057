import React, { useEffect } from 'react'
import Section from '../components/Section'
// import AwardSectionStyle2 from '../components/Section/AwardSection/AwardSectionStyle2'
import BannerSectionStyle3 from '../components/Section/BannerSection/BannerSectionStyle3'
// import GallerySection from '../components/Section/GallerySection'
import { pageTitle } from '../helpers/PageTitle'
// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import { getAppSetting } from '../redux/actions/article'

export default function Penngurus() {
  // ** Store Vars
  const dispatch = useDispatch()
  const article = useSelector((state) => state.article)
  useEffect(() => {
    dispatch(getAppSetting(0))
  }, [dispatch])

  pageTitle('Kebijakan Privasi')
  return (
    <>
      <BannerSectionStyle3
        bgUrl='/images/about/banner_bg.svg'
        imgUrl='/images/about/banner-img-658x555.png'
        title='Privacy Policy <br />IDI Cabang Jakarta Timur'
        subTitle='IDI Jaktim adalah bagian dari Ikatan Dokter 
        Indonesia yang berwewenang di Jakarta Timur.'
      />
      <div className='container'>
        <Section topMd={90} topLg={45} topXl={5}>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='cs_blog_details'>
                {/* Render the HTML content */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: article.privacy?.value
                  }}
                />
              </div>
              {/* <Spacing md="85" />
            <AuthorWidget
              imgUrl="/images/blog/author.png"
              name="Author Bio"
              description="John Smith is a freelance writer and content strategist with a passion for helping businesses tell their stories. With over 10 years of experience in the industry, John has worked with a wide range of clients, from startups to Fortune 500 companies. He holds a Bachelor's degree in English from the University of California, Los Angeles (UCLA), and is an avid reader and traveler in his free time. Follow him on Twitter @johnsmithwriter for the latest updates on his work."
            /> */}
              {/* <Spacing md="110" />
            <CommentsWidget title="Comments" /> */}
              {/* <Spacing md="92" />
            <ReplyWidget title="Leave a Reply" /> */}
            </div>
          </div>
        </Section>
      </div>
    </>
  )
}
